<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn></template
    >
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
    />
  </static-fullscreen-card>
</template>

<script>
export default {
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: [
        {
          name: "calldate",
          type: "datetime",
          title: "Время",
          sortable: true,
          width: 130,
        },
        {
          name: "status",
          type: "select",
          options: this.$store.getters["config/get"].enums.callStatuses,
          title: "Статус",
          sortable: true,
          width: 100,
        },
        {
          name: "destination",
          type: "select",
          options: this.$store.getters["config/get"].enums.destinations,
          title: "Направление",
          sortable: true,
          width: 100,
        },
        {
          name: "manager_name",
          type: "string",
          filter: {
            type: "selectapi",
            name: "users.id",
            api: "/manager/users/select",
          },
          title: "Менеджер",
          sortable: true,
          width: 150,
        },
        {
          name: "phone",
          type: "phone",
          title: "Телефон",
          filter: { type: "text", name: "phone" },
          sortable: true,
          width: 150,
        },
        {
          name: "billsec",
          type: "string",
          title: "Время разговора",
          sortable: true,
          width: 120,
        },
        {
          name: "recordingfile",
          type: "record",
          title: "Запись разговора",
          sortable: false,
          width: 350,
        },
      ],
      url: "/caller/calls",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Записи разговоров";
  },
};
</script>